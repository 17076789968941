import logo from './logo.png';

import './App.css';
import { createStyles, Title,Paper, Card,Text, Image,Button, Code, Container, Space, rem } from '@mantine/core';
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan('sm')]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  dots: {
    position: 'absolute',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(28),
      textAlign: 'left',
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  control: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan('xs')]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      '&:not(:first-of-type)': {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

function App() {
  const { classes } = useStyles();

  return (

    <div className="App">
  

 
    <Container className={classes.wrapper} size={1400}>



<Title></Title>
<Image maw={240} mx="auto" radius="md" src={logo} alt="Logo" />
<Space h="lg"></Space>
<Card>

 {/* <Text>
The cloud took away Internet publishing off our reach and is now captured by large players. </Text>
 <Space h="lg"></Space> */}
<Space h="md"/>

<Title>
🦹🏼‍♂️ Secure URL to localhost 🦹🏼‍♂️
</Title>
<Space h="md"/>

 <Text>
UrlyUp lets you publish early from your own computer or any host, anywhere. Think of it as a secured public URL pointing to your locally 
hosted content or any other host under your control.    
</Text>
<Space h="lg"></Space>
      {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <Text>
Publish any HTTP port (think 8080, 3000) with a static HTTPS URL for deployment or debugging.

        </Text>
        <Space h="md"></Space>

        <Text>
        <Code>
        For example: https://3000-R5TR4G-free.urlyup.com  will now hit port 3000 of your Flask, Node or Deno app! No SSL to configure.
 
 </Code>
        </Text>

<Space h="md"></Space>

<Text>
Your apps are instantly accessible  without the need for external 
resources but a simple broadband connection to the Internet.       
</Text>

<Space h="lg"></Space>

<Text>
Your can now turn that proof of concept you just coded on your laptop into a site that can be visited by your first supporters, and changes in your dev environment will reflect immediately.       
</Text>

<Space h="lg"></Space>


  <Text>

Web and backend developers are UrlyUp's first class citizens since publishing port is often a starter (Self hosters can also access this URL to securely access self-hosted apps):  
</Text>
<Space h="lg"></Space>
 {/* <Text>
Everyone will soon sound here easy deploy solutions for common apps and free open source alternatives to cloud vendors solutions to be deployed in-house.     </Text>
<Space h="lg"></Space> */}



        <Space h="md"/>

        <Title>
         💻 Instructions 💻 
        </Title>
        <Space h="md"/>
        <Text>
One line Publishing command for Debian/Ubuntu (Read below for other OSes):
        </Text>
        <Space h="md"/>
        <Code> wget -O - https://urlyup.com/debian.sh | bash </Code> 
        <Space h="md"/>
        <Text >
         After this command executes on a host, for example an Ubuntu host connected to Internet connection, it will immediately configure the host to be reached through the URL provided when the command returns. 
        </Text>
        <Text >
By default, only port 3000 will be exposed to your secured URL. Only a private IP, not connected to the Internet but to UrlyUp private network, is allocated to your host. The UrlyUp free URL connects the world to that private IP on port 3000 by default.  
</Text>
<Text>
Your private IP, to which your app should be listening to, for the UrlyUp URL to reach it, is available by issuing the following command after being succesfully connected to UrlyUp:  </Text>
<Code>wg show wg0</Code> 

 <Space h="md"/>
<Text >
To disable the URL simply issue the following command at the prompt:       </Text>
<Code> wg-quick down wg0</Code> 
        <Space h="md"/>
        <Text >
To re-enable the URL simply issue:       </Text>
<Code> wg-quick up wg0</Code>        
        <Space h="md"/>
        <Text >
         Or, on any other Operating System, run the following command in your terminal to get an UrlyUp URL. 
         Easy setup instruction is included in the API call response for the most popular OSes. 
         The API response also contains a host connect configuration and a TLS protect URL of your own to connect to the host.
         🦹🏼‍♂️
        </Text>


        <Space h="md"/>
        <Code>curl https://urlyup.com/free</Code> 

        <Space h="lg"/>

        <Text>
         From Ubuntu (Debian) get a URL to any port with a one-liner  command:
        </Text>


        <Space h="md"/>
        <Code> wget -O - https://urlyup.com/debian.sh | bash </Code> 
 <Space h="md"/>
          <Text>
         Just add the port number and a dash before the URL to reach a port such as 3000 .
                <Code>
        For example: https://3000-R5TR4G-free.urlyup.com  will now hit port 3000 of your Flask, Node or Deno app! No SSL to configure.
 
 </Code>
        </Text>
        

        <Space h="lg"/>

       

        <a
          className="App-link"
          href="https://setip.io/urlyup"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://setip.io/urlyup
        </a>
      {/* </header> */}
      </Card>
      </Container>

      <Text>
        
        © SETIP.IO 2023 - All rights reserved.
        </Text>

    </div>
  );
}

export default App;
